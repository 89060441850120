<template>
  <v-container v-if="!validState">
    <v-row class="justify-center align-center">
      <v-col class="justify-center align-center">
        <v-card class="pa-4">
          <span v-if="dataLoading || loading">
            <v-progress-circular color="primary" :size="200" :width="4" indeterminate>
              Data Loading...</v-progress-circular
            >
          </span>
          <span v-else class="secondary--text">
            <b> Learn at least 5 words to unlock Play. </b>
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <span>Score: {{ score }}%</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-card class="pa-4">
          <!-- Top Images -->
          <v-row class="d-flex justify-center">
            <v-col
              v-for="(word, index) in words.slice(0, 2)"
              :key="index"
              cols="6"
              class="d-flex justify-center">
              <v-card @click="selectImage(index)">
                <v-img
                  class="image"
                  :id="index"
                  :class="flipped && index != correctImageIndex ? 'hide' : ''"
                  :style="flipped ? `border: 2px solid ${color(index)}` : ''"
                  :src="word.image_url"
                  :width="250"
                  :max-height="300"
                  @error="replaceWord(word, index)"></v-img>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <!-- Word -->
              <v-tooltip color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <h3 class="primary--text">
                      {{ topWord.lexeme.word }}
                      {{ flipped ? `- ${topWord.lexeme.english_meanings}` : "" }}
                    </h3>
                  </span>
                </template>
                <span> {{ topWord.lexeme.pronounciation }} </span>
              </v-tooltip>
              <!-- Dictionary URL -->
              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(dictionaryURL)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span> Open in Dictionary</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="flipped">
            <v-col class="d-flex justify-center">
              <v-img
                v-if="correct"
                :src="require('@/assets/images/smile_starfish.jpg')"
                max-height="50"
                max-width="50"></v-img>
              <core-btn-primary @click="next()"> Next <v-icon>mdi-arrow-right</v-icon> </core-btn-primary>
            </v-col>
          </v-row>
          <!-- Bottom Images -->
          <v-row class="d-flex justify-center">
            <v-col
              v-for="(word, index) in words.slice(2, 4)"
              :key="index"
              cols="6"
              class="d-flex justify-center">
              <v-card @click="selectImage(index + 2)">
                <v-img
                  :id="index + 2"
                  class="image"
                  :class="flipped && index + 2 != correctImageIndex ? 'hide' : ''"
                  :style="flipped ? `border: 4px solid ${color(index + 2)}` : ''"
                  :src="word.image_url"
                  :width="250"
                  :max-height="300"
                  @error="replaceWord(word, index + 2)"></v-img>
              </v-card>
            </v-col>
          </v-row>

          <!-- Sentence -->
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <v-tooltip color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="text-body-1" v-bind="attrs" v-on="on">
                    {{ blankedSentence }}
                  </span>
                </template>
                <span class="ma-1">
                  <v-row v-if="flipped">{{ topWord.sentence_translation }}</v-row>
                  <v-row v-if="topWord.sentence_pronounciation">
                    <span class="text-body-1" v-html="topWord.sentence_pronounciation"></span>
                  </v-row>
                </span>
              </v-tooltip>

              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(topWord.sentence_url)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span> Open in Tatoeba</span>
              </v-tooltip>
            </v-col>
            <!-- Audio -->
            <v-col v-if="flipped" cols="12" class="d-flex justify-center">
              <audio controls autoplay :src="topWord.sentence_audio_src"></audio
            ></v-col>
          </v-row>
          <!-- hint -->
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <v-tooltip v-if="!flipped && topWord.hint" color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> Hint </span>
                </template>
                <span> {{ topWord.hint }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <hr />
    <translator> </translator>
  </v-container>
</template>

<style scoped>
.image:hover {
  transition: 0.4s ease;
  opacity: 0.7;
}

.hide {
  transition: 0.8s ease;
  opacity: 0;
}
</style>

<script>
import translator from "@/components/SRS/translator.vue"

export default {
  components: {
    "translator": translator,
  },

  data: function () {
    return {
      loading: false,
      flipped: false,
      activeIndexes: [],
      blacklistedIndexes: [],
      words: [],
      correctWordIndex: 0,
      correctImageIndex: 0,
      correct: false,
      right: 0.0,
      wrong: 0.0,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    dataLoading() {
      return this.$store.state.dataLoading
    },
    score() {
      if (this.right + this.wrong == 0) return 100
      return Math.round((100 * this.right) / (this.right + this.wrong))
    },
    wordList() {
      if (this.$store.state.srsPlayList.length > 0) {
        return this.$store.state.srsPlayList
      } else {
        return this.$store.state.srsRecallList
      }
    },
    topWord() {
      return this.wordList.length > 0 ? this.wordList[this.correctWordIndex] : { lexeme: {} }
    },
    blankedSentence() {
      if (this.topWord) {
        if (this.topWord.sentence_text) {
          return this.topWord.sentence_text.replace(this.topWord.lexeme.word, "____")
        }
      }
      return ""
    },
    dictionaryURL() {
      if (this.topWord) {
        if (this.topWord.lexeme) {
          return `https://japandict.com/${this.topWord.lexeme.word}`
        }
      }
      return ""
    },
    validState() {
      return this.wordList.length - this.blacklistedIndexes.length >= 4
    },
  },

  methods: {
    next() {
      this.flipped = !this.flipped
      this.fourRandom()
    },
    checkDuplicates(index) {
      if (this.activeIndexes.includes(index)) return true
      if (this.blacklistedIndexes.includes(index)) return true

      return false
    },
    color(index) {
      if (index == this.correctImageIndex) {
        return "green"
      } else {
        return "red"
      }
    },
    getRandomIndex() {
      let index = Math.floor(Math.random() * (this.wordList.length - 1))

      if (this.checkDuplicates(index) && this.validState) {
        return this.getRandomIndex()
      } else {
        return index
      }
    },
    replaceWord(word, index) {
      this.blacklistedIndexes.push(this.activeIndexes[index])

      let newIndex = this.getRandomIndex()
      this.words[index] = this.wordList[newIndex]
      this.activeIndexes[index] = newIndex

      if (index == this.correctImageIndex) this.correctWordIndex = newIndex

      this.$forceUpdate()
    },
    fourRandom() {
      this.correctImageIndex = Math.floor(Math.random() * 4)

      let i = 0
      this.words = []
      this.activeIndexes = []
      while (i < 4) {
        let index = this.getRandomIndex()

        this.activeIndexes.push(index)
        this.words.push(this.wordList[index])

        if (i == this.correctImageIndex) this.correctWordIndex = index
        i++
      }
    },
    selectImage(index) {
      this.correct = index == this.correctImageIndex
      if (this.correct) this.right = this.right + 1
      else this.wrong = this.wrong + 1

      this.flipped = true
    },
    openURL(url) {
      window.open(url, "_href")
    },
  },
  mounted: async function () {
    if (this.validState) this.fourRandom()

    if (this.$store.state.srsPlayList.length == 0) {
      this.loading = true

      let params = `language=${this.user.srs_user.language}`
      if (this.user.srs_user.level) params = `${params}&level=${this.user.srs_user.level}`
      if (this.user.srs_user.partOfSpeech)
        params = `${params}&part_of_speech=${this.user.srs_user.partOfSpeech}`
      if (this.user.srs_user.category) params = `${params}&category=${this.user.srs_user.category}`

      await this.$django_api.get(`srs/userword?${params}`).then((response) => {
        this.$store.state.srsPlayList = response.data
      })

      this.loading = false
    }
  },
  watch: {
    wordList: {
      handler(val) {
        if (this.validState) this.fourRandom()
      },
    },
  },
}
</script>
