var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.validState)?_c('v-container',[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{staticClass:"justify-center align-center"},[_c('v-card',{staticClass:"pa-4"},[(_vm.dataLoading || _vm.loading)?_c('span',[_c('v-progress-circular',{attrs:{"color":"primary","size":200,"width":4,"indeterminate":""}},[_vm._v(" Data Loading...")])],1):_c('span',{staticClass:"secondary--text"},[_c('b',[_vm._v(" Learn at least 5 words to unlock Play. ")])])])],1)],1)],1):_c('v-container',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("Score: "+_vm._s(_vm.score)+"%")])])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.words.slice(0, 2)),function(word,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('v-card',{on:{"click":function($event){return _vm.selectImage(index)}}},[_c('v-img',{staticClass:"image",class:_vm.flipped && index != _vm.correctImageIndex ? 'hide' : '',style:(_vm.flipped ? ("border: 2px solid " + (_vm.color(index))) : ''),attrs:{"id":index,"src":word.image_url,"width":250,"max-height":300},on:{"error":function($event){return _vm.replaceWord(word, index)}}})],1)],1)}),1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-tooltip',{attrs:{"color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.topWord.lexeme.word)+" "+_vm._s(_vm.flipped ? ("- " + (_vm.topWord.lexeme.english_meanings)) : "")+" ")])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.topWord.lexeme.pronounciation)+" ")])]),_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openURL(_vm.dictionaryURL)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(" Open in Dictionary")])])],1)],1),(_vm.flipped)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.correct)?_c('v-img',{attrs:{"src":require('@/assets/images/smile_starfish.jpg'),"max-height":"50","max-width":"50"}}):_vm._e(),_c('core-btn-primary',{on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.words.slice(2, 4)),function(word,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('v-card',{on:{"click":function($event){return _vm.selectImage(index + 2)}}},[_c('v-img',{staticClass:"image",class:_vm.flipped && index + 2 != _vm.correctImageIndex ? 'hide' : '',style:(_vm.flipped ? ("border: 4px solid " + (_vm.color(index + 2))) : ''),attrs:{"id":index + 2,"src":word.image_url,"width":250,"max-height":300},on:{"error":function($event){return _vm.replaceWord(word, index + 2)}}})],1)],1)}),1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-tooltip',{attrs:{"color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-body-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.blankedSentence)+" ")])]}}])},[_c('span',{staticClass:"ma-1"},[(_vm.flipped)?_c('v-row',[_vm._v(_vm._s(_vm.topWord.sentence_translation))]):_vm._e(),(_vm.topWord.sentence_pronounciation)?_c('v-row',[_c('span',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.topWord.sentence_pronounciation)}})]):_vm._e()],1)]),_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openURL(_vm.topWord.sentence_url)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(" Open in Tatoeba")])])],1),(_vm.flipped)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('audio',{attrs:{"controls":"","autoplay":"","src":_vm.topWord.sentence_audio_src}})]):_vm._e()],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[(!_vm.flipped && _vm.topWord.hint)?_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Hint ")])]}}],null,false,3517824708)},[_c('span',[_vm._v(" "+_vm._s(_vm.topWord.hint))])]):_vm._e()],1)],1)],1)],1)],1),_c('br'),_c('hr'),_c('translator')],1)}
var staticRenderFns = []

export { render, staticRenderFns }